
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { ApiDebtor, ApiDocumentCenter } from "@/core/api";
import { TaggingItem } from "@/core/directive/interface/common";
import { DocumentCenterBillingTableFilter } from "@/core/directive/interface/documentCenter";

export default defineComponent({
  name: "document-center-billing-verification-dropdown",
  emits: ["submit-filter", "reset-filter"],
  props: {
    type: {
      type: Number,
      default: 0, // 0: billing 1: payment
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);

    const formData = ref<DocumentCenterBillingTableFilter>({
      doc_configuration_id: [],
      doc_date: "",
      doc_date_start: "",
      doc_date_end: "",
      doc_number: "",
      precedent_doc_number: "",
      creditor_entity_id: "",
      debtor_entity_id: "",
      status: [15],
    });

    const options = ref({
      scourceOptions: [] as TaggingItem[],
      typeOptions: [] as TaggingItem[],
      creditorLoading: false,
      creditorOptions: [] as any[],
      debtorLoading: false,
      debtorOptions: [] as any[],
      statusOptions: [] as TaggingItem[],
    });

    const getEntityData = async (value, callback) => {
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      callback(data);
      if (data.code == 0) {
        options.value.creditorOptions = data.data;
      }
    };

    const getCreditorData = (query: string) => {
      options.value.creditorLoading = true;
      getEntityData(query, (data) => {
        options.value.creditorLoading = false;
        if (data.code == 0) {
          options.value.creditorOptions = data.data;
        }
      });
    };

    const debounceCreditorSearch = _.debounce(getCreditorData, 1000);

    const searchCreditorItems = (query: string) => {
      debounceCreditorSearch(query);
    };

    const getDebtorData = (query: string) => {
      options.value.debtorLoading = true;
      getEntityData(query, (data) => {
        options.value.debtorLoading = false;
        if (data.code == 0) {
          options.value.debtorOptions = data.data;
        }
      });
    };

    const debounceDebtorSearch = _.debounce(getDebtorData, 1000);

    const searchDebtorItems = (query: string) => {
      debounceDebtorSearch(query);
    };

    const getDocumentDocType = async () => {
      const { data } = await ApiDocumentCenter.getDocumentDocType({
        max_item: "all",
        category: "billing",
      });
      if (data.code == 0) {
        options.value.typeOptions = data.data;
      }
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      if (props.type === 0) {
        // billing
        getDocumentDocType();
      }
      options.value.statusOptions = [
        {
          value: 10,
          label: t("documentCenter.Verified"),
        },
        {
          value: 15,
          label: t("documentCenter.Pending"),
        },
      ];
    });

    return {
      t,
      formData,
      formRef,
      options,
      searchCreditorItems,
      searchDebtorItems,
      getDocumentDocType,
      submit,
      handleReset,
    };
  },
});
